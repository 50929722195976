import React from 'react';

import Layout from '../components/Layout';

import Scroll from '../components/Scroll';

import pic1 from '../assets/images/pic01.jpg';
import serverbay from '../assets/images/serverbay.jpg';
import security from '../assets/images/security.jpg';
import './_custom.scss';
import logoAnime from '../assets/images/logoAnimev4.gif';
import config from '../../config';
const IndexPage = () => (
  <Layout>
    <section id="banner">
      <div className="inner">
        {/*<h2>{config.heading}</h2>*/}
        {/*<img src={logoAnime} alt="" />*/}
        <div id="main-img" />
        {/*<p></p>*/}
        {/*<p></p>*/}
        {/*<br/>*/}
        {/*<br/>*/}
        {/*<br/>*/}
        {/*<p>{config.subHeading}</p>*/}
        {/*<ul className="actions special">*/}
        {/*  <li>*/}
        {/*    <Scroll type="id" element="one">*/}
        {/*      <a href="/#" className="button primary">*/}
        {/*        Explore*/}
        {/*      </a>*/}
        {/*    </Scroll>*/}
        {/*  </li>*/}
        {/*</ul>*/}
      </div>
      <Scroll type="id" element="one">
        <a href="#one" className="more">
          En apprendre plus
        </a>
      </Scroll>
    </section>

    <section id="one" className="wrapper style1 special">
      <div className="inner">
        <header className="major">
          <h2>
            Nitramis est une société de services informatiques.
            <br />
            Nous aidons les petites et moyennes entreprises.
          </h2>
          <p>
            Nous offrons différents services informatiques, de la création d'un site web statique à la création d'une plateforme de formation en ligne, en passant par l'hébergement, les services de messagerie, etc  ...
            <br />
            Nous sommes la pour vous aider à prendre un virage numérique dans la statégie et l'organisation interne de votre société.
          </p>
        </header>
        <ul className="icons major">
          <li>
            <span className="icon fa-gem major style1">
              <span className="label">Lorem</span>
            </span>
          </li>
          <li>
            <span className="icon fa-heart major style2">
              <span className="label">Ipsum</span>
            </span>
          </li>
          <li>
            <span className="icon solid fa-code major style3">
              <span className="label">Dolor</span>
            </span>
          </li>
        </ul>
      </div>
    </section>

    <section id="two" className="wrapper alt style2">
      <section className="spotlight">
        <div className="image">
          <img src={pic1} alt="" />
        </div>
        <div className="content">
          <h2>
            Conception de site web
          </h2>
          <p>
            Que vous ayez besoin d'une simple page web pour votre association ou d'un site web d'Ecommerce pour vendre vos produits ou services partout dans le monde, notre expertise dans ce domaine saura répondre à vos attentes.
          </p>
        </div>
      </section>
      <section className="spotlight">
        <div className="image">
          <img src={serverbay} alt="" />
        </div>
        <div className="content">
          <h2>
            Hébergements web
          </h2>
          <p>
            Nous proposons différents hébergements web pour votre site ou plateforme de formation.
            Les serveurs que nous utilisons sont très rapides et très performants.
            Nous faisons affaire avec de grands hébergeurs web tel que Google.
          </p>
        </div>
      </section>
      <section className="spotlight">
        <div className="image">
          <img src={security} alt="" />
        </div>
        <div className="content">
          <h2>
            Sécurité
          </h2>
          <p>
            Profitez de services comme l’accélération et l’optimisation de contenu, la protection contre les robots, l’authentification à deux facteurs.
            En outre, vous avez la possibilité d’ajouter des options de sécurité supplémentaires, notamment un pare-feu pour application Web et une protection contre les attaques par déni de service (DDoS).
            Ainsi que le blocage GeoIP, l'IP ban (brute force attack).
          </p>
        </div>
      </section>
    </section>

    <section id="three" className="wrapper style3 special">
      <div className="inner">
        <header className="major">
          <h2>Services de Messagerie & intranet</h2>
          <p>
            Besoin d'une messagerie web performante ainsi que des outils de travail comme la suite office.
            La communication par vidéo conférence est maintenant devenu indispensable que se soit pour communiquer avec vos clients ou en interne.
            Nous proposons de vous aider à construire votre site intranet et vous donner les outils/formations pour le faire grandir avec votre entreprise.
          </p>
        </header>
        {/*<ul className="features">*/}
        {/*  <li className="icon fa-at">*/}
        {/*    <h3>Arcu accumsan</h3>*/}
        {/*    <p>*/}
        {/*      Augue consectetur sed interdum imperdiet et ipsum. Mauris lorem*/}
        {/*      tincidunt nullam amet leo Aenean ligula consequat consequat.*/}
        {/*    </p>*/}
        {/*  </li>*/}
        {/*  <li className="icon solid fa-laptop">*/}
        {/*    <h3>Ac Augue Eget</h3>*/}
        {/*    <p>*/}
        {/*      Augue consectetur sed interdum imperdiet et ipsum. Mauris lorem*/}
        {/*      tincidunt nullam amet leo Aenean ligula consequat consequat.*/}
        {/*    </p>*/}
        {/*  </li>*/}
        {/*  <li className="icon solid fa-code">*/}
        {/*    <h3>Mus Scelerisque</h3>*/}
        {/*    <p>*/}
        {/*      Augue consectetur sed interdum imperdiet et ipsum. Mauris lorem*/}
        {/*      tincidunt nullam amet leo Aenean ligula consequat consequat.*/}
        {/*    </p>*/}
        {/*  </li>*/}
        {/*  <li className="icon solid fa-headphones-alt">*/}
        {/*    <h3>Mauris Imperdiet</h3>*/}
        {/*    <p>*/}
        {/*      Augue consectetur sed interdum imperdiet et ipsum. Mauris lorem*/}
        {/*      tincidunt nullam amet leo Aenean ligula consequat consequat.*/}
        {/*    </p>*/}
        {/*  </li>*/}
        {/*  <li className="icon fa-heart">*/}
        {/*    <h3>Aenean Primis</h3>*/}
        {/*    <p>*/}
        {/*      Augue consectetur sed interdum imperdiet et ipsum. Mauris lorem*/}
        {/*      tincidunt nullam amet leo Aenean ligula consequat consequat.*/}
        {/*    </p>*/}
        {/*  </li>*/}
        {/*  <li className="icon fa-flag">*/}
        {/*    <h3>Tortor Ut</h3>*/}
        {/*    <p>*/}
        {/*      Augue consectetur sed interdum imperdiet et ipsum. Mauris lorem*/}
        {/*      tincidunt nullam amet leo Aenean ligula consequat consequat.*/}
        {/*    </p>*/}
        {/*  </li>*/}
        {/*</ul>*/}
      </div>
    </section>


    <section id="four" className="wrapper style4 special">
      <div className="inner">
        <header className="major">
          <h2>Contactez-Nous</h2>
          <br />
          <p>
            <ul>
              <li>
                <p><strong>ADRESSE</strong></p>
                <p>1331 rue dolan, Québec, QC G1W3W3</p>
              </li>
              <li>
                <p><strong>COURRIEL</strong></p>
                <p>contact@nitramis.com</p>
              </li>
              <li>
                <p><strong>TÉLÉPHONE</strong></p>
                <p>418.570.0401</p>
              </li>
            </ul>
          </p>
          <ul className="actions stacked">
            <li>
              <a href="mailto:contact@nitramis.com" className="button fit primary">
                Email
              </a>
            </li>
            {/*<li>*/}
            {/*  <a href="/#" className="button fit">*/}
            {/*    Learn More*/}
            {/*  </a>*/}
            {/*</li>*/}
          </ul>
        </header>
      </div>
    </section>
  </Layout>
);

export default IndexPage;
